import React from "react";
import { Link } from 'react-router-dom';
import IncentiveEngine from "../../views/incentiveEngine";


const OptionsMenu = (props) => {
    if (props.site === "motorDeIncentivos") {
        return (
            <IncentiveEngine
                isAdmin={props.isAdmin}
                isAdminCashbackFiles={props.isAdminCashbackFiles}
                isBackofficeCashbackFiles={props.isBackofficeCashbackFiles}
                keycloak={props.keycloak}
                logout={props.logout}
            />
        )
    }

    return (
        <div className="admin-menu">
            <div className="admin-menu-header">Selecione uma opção</div>
            {props.isAdmin &&
                <div className="buttons-admin-menu">
                    {props.site === "pontos" &&
                        <>
                            <button
                                className="button-admin"
                                id="register-campaign"
                                onClick={() => props.showPartnerArea()}
                            >
                                Parceiros
                            </button>
                            <button
                                className="button-admin"
                                id="register-partner"
                                onClick={() => props.showCampaignArea()}
                            >
                                Campanhas
                            </button>
                            <button
                                className="button-admin"
                                id="import-csv"
                                onClick={() => props.showImportArea()}
                            >
                                Importar CSV
                            </button>
                            <button
                                className="button-admin"
                                id="configuration"
                                onClick={() => props.showConfigurationArea()}
                            >
                                Configurações
                            </button>
                        </>
                    }
                    {props.site === "cashback" &&
                        <>
                            <Link className="button-admin"
                                id="eletronic-extract"
                                to="/eletronic-extract">
                                Extrato Eletrônico
                            </Link>
                        </>
                    }
                </div>
            }
            {(props.isPartner || props.isBackoffice) &&
                <div className="buttons-admin-menu">
                    {props.site === "cashback" &&
                        <Link className="button-admin"
                            id="eletronic-extract"
                            to="/eletronic-extract">
                            Extrato Eletrônico
                        </Link>
                    }
                    {props.site === "pontos" &&
                        <button
                            className="button-admin"
                            id="import-csv"
                            onClick={() => props.showImportArea()}
                        >
                            Importar Arquivos Campanhas
                        </button>
                    }
                </div>
            }
            {(props.isBackofficeGetnetReleasePayment) &&
                <div className="buttons-admin-menu">
                    {props.site === "cashback" &&
                        <Link className="button-admin"
                            id="liquidation"
                            to="/liquidation">
                            Liquidação
                        </Link>
                    }
                </div>
            }
        </div >
    );
}

export default OptionsMenu;
